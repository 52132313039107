define("discourse/plugins/discourse-wp-search/discourse/connectors/full-page-search-below-search-info/wp-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const PAGE_LIMIT = 10;
  var _default = _exports.default = {
    actions: {
      nextPage() {
        let _this = this.parentView.parentView._target;
        _this.incrementProperty("page");
        _this._search();
      },
      firstPage() {
        let _this = this.parentView.parentView._target;
        _this.set("page", 1);
        _this._search();
      },
      lastPage() {
        let _this = this.parentView.parentView._target;
        _this.set("page", _this.totalPages);
        _this._search();
      },
      prevPage() {
        let _this = this.parentView.parentView._target;
        _this.decrementProperty("page");
        _this._search();
      }
    }
  };
});