define("discourse/plugins/discourse-wp-search/discourse/initializers/discourse-wp-search", ["exports", "discourse/lib/plugin-api", "discourse/lib/ajax", "discourse/lib/search", "discourse/lib/page-tracker"], function (_exports, _pluginApi, _ajax, _search, _pageTracker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "discourse-wp-search",
    initialize(container) {
      const siteSettings = container.lookup("site-settings:main");
      if (siteSettings.discourse_wp_search_enabled) {
        (0, _pluginApi.withPluginApi)("0.1", api => {
          const pageSearchController = api.container.lookup("controller:full-page-search");
          pageSearchController.searchTypes.pushObject({
            name: "Articles",
            id: "articles",
            wp_subtype: "post"
          });
          pageSearchController.searchTypes.pushObject({
            name: "Colleges",
            id: "colleges",
            wp_subtype: "college"
          });
          let _orig_search = pageSearchController._search;
          pageSearchController._search = function () {
            if (["articles", "colleges"].indexOf(this.search_type) !== -1) {
              this.set("isWpSearch", true);
              const searchTerm = this.searchTerm;
              let wp_subtype = this.searchTypes.findBy("id", this.search_type).wp_subtype;
              if (!(0, _search.isValidSearchTerm)(searchTerm, this.siteSettings)) {
                this.set("invalidSearch", true);
                return;
              }
              let args = {
                search: searchTerm,
                q: searchTerm,
                subtype: wp_subtype,
                page: this.page
              };
              const searchKey = (0, _search.getSearchKey)(args);
              delete args.q;
              this.setProperties({
                searching: true,
                loading: true
              });
              this.set('hasPreviousPage', false);
              this.set('hasNextPage', false);
              this.set('hasFirstPage', false);
              this.set('hasLastPage', false);
              let wpBaseUrl = siteSettings.discourse_wp_search_base_url.replace(/\/+$/, '');
              (0, _ajax.ajax)(wpBaseUrl + "/wp-json/wp/v2/search", {
                data: args,
                returnXHR: true
              }).then(async res => {
                var results = res.result;
                var xhr = res.xhr;
                var total_results = xhr.getResponseHeader("X-WP-Total");
                var total_pages = xhr.getResponseHeader("X-WP-TotalPages");
                // console.log("total_pages: ", total_pages);
                // console.log("this.page: ", this.page);

                this.setProperties({
                  usingWpSearch: true,
                  wpSearchResults: results,
                  totalResults: total_results,
                  totalPages: total_pages,
                  currentPage: this.page,
                  hasPreviousPage: this.page > 1,
                  hasNextPage: this.page < total_pages,
                  hasFirstPage: this.page != 1,
                  hasLastPage: this.page != total_pages,
                  resultCount: total_results
                });
              }).finally(() => {
                this.setProperties({
                  searching: false,
                  loading: false
                });
              });
              return;
            } else {
              this.set("isWpSearch", false);
            }
            _orig_search.apply(this, arguments);
          };
        });
      }
    }
  };
});